/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, css, Grid, Row, up, styled, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import Button from '_platform/src/components/Button/Button';
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';

import bgDesktop1x from './images/hp-login-banner-@1x.jpg';
import bgDesktop2x from './images/hp-login-banner-@2x.jpg';
import bgMobile1x from './images/hp-login-banner-mobile-@1x.jpg';
import bgMobile2x from './images/hp-login-banner-mobile-@2x.jpg';
import bgTablet1x from './images/hp-login-banner-tablet-@1x.jpg';
import bgTablet2x from './images/hp-login-banner-tablet-@2x.jpg';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  background-image: url(${bgMobile1x});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 2rem 0;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-image: url(${bgMobile2x});
  }
  ${up(
    'sm',
    css`
      background-image: url(${bgTablet1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bgTablet2x});
      }
    `
  )}
  ${up(
    'lg',
    css`
      background-image: url(${bgDesktop1x});
      @media only screen and (min-device-pixel-ratio: 1.5),
        only screen and (min-resolution: 144dpi),
        only screen and (min-resolution: 1.5dppx) {
        background-image: url(${bgDesktop1x});
        background-color: #003865;
      }
      @media only screen and (min-width: 4096px) {
        background-image: url(${bgDesktop2x});
        background-color: #003865;
      }
    `
  )}
`;

/*const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 222px;
  }
`;*/

const IntroContainer = styled.div`
  border: 1px solid #fff;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding: 2rem;
  h2 {
    font-size: 30px;
  }
  p {
    color: #fff;
  }
  a {
    color: #fff;
    &:visited {
      color: #fff;
    }
  }
`;

const RegisterContainer = styled.div`
  max-width: 500px;
  //padding-left: 50px;
`;

const LoginContainer = styled.div`
  max-width: 500px;

  h2 {
    font-size: 20px;
    font-weight: bold;
  }

  .reset-password {
    a {
      font-size: 14px;
      font-weight: normal;
    }
  }

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <IntroContainer>
        <Grid className="page-container">
          <Row justifyContent="center">
            <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
              <RegisterContainer>
                <h2>
                  <strong>Welcome</strong> to{' '}
                  <strong>HP Commercial Club</strong>
                </h2>
                <p className="spacer--bottom--small">
                  The HP Commercial Club is designed to reward your Partner
                  Businesses for your sales of HP products. The more HP products
                  you sell, the more reward dollars you receive.
                </p>
                <p className="spacer--bottom--small">
                  Register below to participate in the HP Commercial Club.
                </p>
                <Button as={Link} className="register-button" to="/register/">
                  Register
                </Button>
              </RegisterContainer>
            </Col>
            <Col xs={12} sm={10} osm={1} lg={6} olg={0}>
              <LoginContainer>
                <h2>Already registered?</h2>
                <p>
                  Please login below using the details already provided to you.
                </p>
                <LoginProvider disableMeta>
                  <LoginForm formLabels={{ labelUserName: 'Username' }} />
                </LoginProvider>
              </LoginContainer>
            </Col>
          </Row>
        </Grid>
      </IntroContainer>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
